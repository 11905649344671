import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TimeComponent',
  props: {
    timevla: {
      type: String,
      default: false
    },
    titleT: {
      type: String,
      default: false
    }
  },
  data: function data() {
    return {
      time: '',
      titleName: '',
      start_time: '',
      end_time: ''
    };
  },
  watch: {
    timevla: function timevla(val) {
      return this.time = val;
    },
    titleT: function titleT(val) {
      return this.titleName = val;
    }
  },
  methods: {
    FinalFollowUpEs: function FinalFollowUpEs(val) {
      console.log(val);
      if (val != null) {
        this.start_time = val[0].toString().substring(0, 10);
        this.end_time = val[1].toString().substring(0, 10);
      } else {
        this.start_time = '';
        this.end_time = '';
      }
      this.$emit('FollowClick', this.start_time, this.end_time, this.titleT);
    },
    Deletesshow: function Deletesshow() {
      this.start_time = '';
      this.end_time = '';
      this.$emit('forkClick', this.titleT);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TimeComponent" },
    [
      _c("span", { staticClass: "nameTitle projectScreening" }, [
        _vm._v(_vm._s(_vm.titleT) + "："),
      ]),
      _vm._v(" "),
      _c("el-date-picker", {
        staticStyle: { width: "350px !important" },
        attrs: {
          size: "small",
          type: "datetimerange",
          "start-placeholder": "开始日期",
          "end-placeholder": "  结束日期",
          "default-time": ["00:00:00", "23:59:59"],
          "value-format": "timestamp",
        },
        on: { change: _vm.FinalFollowUpEs },
        model: {
          value: _vm.time,
          callback: function ($$v) {
            _vm.time = $$v
          },
          expression: "time",
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          on: {
            click: function ($event) {
              return _vm.Deletesshow()
            },
          },
        },
        [_c("i", { staticClass: "el-icon-close" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container CollegeManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 10, sm: 10, md: 10, lg: 8, xl: 6 } },
                      [_c("Head", { attrs: { name: _vm.title } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.radioFix()
                              },
                            },
                            model: {
                              value: _vm.listThematicChecked,
                              callback: function ($$v) {
                                _vm.listThematicChecked = $$v
                              },
                              expression: "listThematicChecked",
                            },
                          },
                          _vm._l(_vm.user_arr, function (item) {
                            return _c(
                              "el-checkbox",
                              { key: item.id, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  _vm._s(item.realname) +
                                    "(" +
                                    _vm._s(item.num) +
                                    ")"
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-right": "0", "margin-top": "20px" },
                    attrs: { gutter: 12 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 16, lg: 16, xl: 14 } },
                      [
                        _c(
                          "div",
                          { staticClass: "source mar-bott-20" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("数据来源："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  size: "small",
                                  clearable: "",
                                },
                                on: { change: _vm.sourceVal },
                                model: {
                                  value: _vm.sourcese,
                                  callback: function ($$v) {
                                    _vm.sourcese = $$v
                                  },
                                  expression: "sourcese",
                                },
                              },
                              _vm._l(_vm.source, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "source mar-bott-20" },
                          [
                            _c("student-level", {
                              on: { studentLevel: _vm.studentLevel },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "source mar-bott-20" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("学员来源："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  width: "180px",
                                  "margin-right": "0",
                                },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请搜索花名",
                                  clearable: "",
                                },
                                on: { change: _vm.flowerArrayEs },
                                model: {
                                  value: _vm.source_client_id,
                                  callback: function ($$v) {
                                    _vm.source_client_id = $$v
                                  },
                                  expression: "source_client_id",
                                },
                              },
                              _vm._l(_vm.flower_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.flower_name,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 16, lg: 8, xl: 10 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 12, lg: 8, xl: 6 } },
                          [
                            _c("display-settingser", {
                              staticClass: "ml10 mar-bott-20 filter-item",
                              attrs: {
                                list_type: _vm.list_type,
                                resultList: _vm.resultES,
                                "display-arr": _vm.displaySettingsWhole,
                                "display-value": _vm.displaySettings,
                              },
                              on: {
                                "update:displayValue": function ($event) {
                                  _vm.displaySettings = $event
                                },
                                "update:display-value": function ($event) {
                                  _vm.displaySettings = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8 } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select mar-bott-20",
                                staticStyle: { width: "200px" },
                                attrs: {
                                  placeholder: "请搜索客户名称",
                                  size: "small",
                                },
                                on: { change: _vm.ClassSearchInput },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.ClassSearchInput($event)
                                  },
                                },
                                model: {
                                  value: _vm.ClassSearch,
                                  callback: function ($$v) {
                                    _vm.ClassSearch = $$v
                                  },
                                  expression: "ClassSearch",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.ClassSearchInput },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 8 } },
                          [
                            _c(
                              "el-input",
                              {
                                staticClass: "input-with-select mar-bott-20",
                                staticStyle: { width: "200px" },
                                attrs: {
                                  placeholder: "请搜索客户电话",
                                  type: "number",
                                  size: "small",
                                },
                                on: { change: _vm.customerMobileInput },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.customerMobileInput($event)
                                  },
                                },
                                model: {
                                  value: _vm.customer_mobile,
                                  callback: function ($$v) {
                                    _vm.customer_mobile = $$v
                                  },
                                  expression: "customer_mobile",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.customerMobileInput },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-right": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-right": "0px" },
                        attrs: { xs: 24, sm: 24, md: 16, lg: 9, xl: 10 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "operation" },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["新建"],
                                    expression: "['新建']",
                                  },
                                ],
                                staticClass: "btnse marginTop",
                                attrs: { type: "danger" },
                                on: { click: _vm.DeliveryToClick },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _vm._v(
                                  "\n                            新建\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btnse marginLeft marginTop",
                                staticStyle: {
                                  background: "#0db9fe",
                                  "border-color": "#0db9fe",
                                },
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.NewCustomer(2)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-edit" }),
                                _vm._v(
                                  "\n                            编辑\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.ImportTable != 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "btnse marginTop",
                                    staticStyle: { "margin-right": "10px" },
                                    attrs: { type: "warning" },
                                    on: { click: _vm.Import },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-edit-outline",
                                    }),
                                    _vm._v(
                                      "\n                            导入\n                        "
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-upload",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["导入"],
                                        expression: "['导入']",
                                      },
                                    ],
                                    staticClass: "btnse marginTop",
                                    staticStyle: {
                                      "padding-top": "0px !important",
                                      "padding-bottom": "0px !important",
                                    },
                                    attrs: {
                                      "http-request": _vm.TeacherExcel,
                                      "show-file-list": true,
                                      action: "action",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btnse",
                                        attrs: { type: "warning" },
                                        on: {
                                          click: function ($event) {
                                            ;(_vm.dialogStatus = "update"),
                                              (_vm.dialogFormVisible = true)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-edit-outline",
                                        }),
                                        _vm._v(
                                          "\n                                导入\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                            _vm._v(" "),
                            _c("my-export-excel", {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["导出"],
                                  expression: "['导出']",
                                },
                              ],
                              staticClass: "btnse marginTop",
                              staticStyle: {
                                "padding-top": "0 !important",
                                "padding-left": "2px !important",
                                "padding-bottom": "0px !important",
                              },
                              attrs: {
                                filename: "我的客户列表",
                                list: _vm.multipleSelection,
                                "label-arr": _vm.displayOptions,
                                "value-arr": _vm.exportValueArr,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["下载标准表"],
                                    expression: "['下载标准表']",
                                  },
                                ],
                                staticClass: "btnse marginTop",
                                staticStyle: { "margin-left": "3px" },
                                attrs: { type: "success" },
                                on: { click: _vm.ExcelModel },
                              },
                              [
                                _c("i", { staticClass: "el-icon-download" }),
                                _vm._v(
                                  "\n                            下载标准表\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-left": "0px",
                          "padding-right": "0",
                        },
                        attrs: { xs: 24, sm: 24, md: 18, lg: 15, xl: 14 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "operation",
                            staticStyle: { float: "right" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["外送项目方"],
                                    expression: "['外送项目方']",
                                  },
                                ],
                                staticClass: "padd marginTop",
                                staticStyle: { "margin-left": "12px" },
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.projectSideBtn },
                              },
                              [_vm._v("外送项目方")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["回归公海"],
                                    expression: "['回归公海']",
                                  },
                                ],
                                staticClass: "padd marginTop",
                                staticStyle: { "margin-left": "12px" },
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.regression },
                              },
                              [_vm._v("回归公海")]
                            ),
                            _vm._v(" "),
                            _vm.projectBtnShow
                              ? _c(
                                  "el-dropdown",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["转移跟进人", "转移创建人"],
                                        expression:
                                          "['转移跟进人', '转移创建人']",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "marginLeft padd marginTop",
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                转移\n                                "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon--right",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          { staticStyle: { padding: "0 0" } },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    "（1）只有该客户的创建人、创建人的主管、系统管理员才有移交创建人的权限。",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "permission",
                                                        rawName: "v-permission",
                                                        value: ["转移创建人"],
                                                        expression:
                                                          "['转移创建人']",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      border: "none",
                                                    },
                                                    on: { click: _vm.transfer },
                                                  },
                                                  [_vm._v("转移创建人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            staticStyle: {
                                              padding: "0 0",
                                              "padding-top": "0 !important",
                                              "padding-bottom": "0 !important",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value: ["转移跟进人"],
                                                    expression:
                                                      "['转移跟进人']",
                                                  },
                                                ],
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "请选择转移的人员",
                                                  placement: "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      border: "none",
                                                    },
                                                    on: {
                                                      click: _vm.followUpPerson,
                                                    },
                                                  },
                                                  [_vm._v("转移跟进人")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-popover",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["共享"],
                                    expression: "['共享']",
                                  },
                                ],
                                attrs: {
                                  placement: "top-start",
                                  title: "共享客户：",
                                  width: "300",
                                  trigger: "hover",
                                  content:
                                    "当前有权限的人员列表：(说明：同部门之间不能共享客户（避免重复数据），同部门之间只能转移客户)",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "marginLeft padd marginTop",
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                      size: "small",
                                    },
                                    on: { click: _vm.share },
                                    slot: "reference",
                                  },
                                  [_vm._v("共享")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["外送公海池"],
                                    expression: "['外送公海池']",
                                  },
                                ],
                                staticClass: "marginLeft padd marginTop",
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.CustomerDeliverIndex },
                              },
                              [_vm._v("外送公海池")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["批量修改学员级别"],
                                    expression: "['批量修改学员级别']",
                                  },
                                ],
                                staticClass: "padd marginTop",
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.BatchModify },
                              },
                              [_vm._v("批量修改学员级别")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "padd marginTop",
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.revisitDays },
                              },
                              [_vm._v("批量修改回访时间")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["批量修改数据类型"],
                                    expression: "['批量修改数据类型']",
                                  },
                                ],
                                staticClass: "padd marginTop",
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.batchEditDataTypeFun },
                              },
                              [_vm._v("批量修改数据类型")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.remarksshow,
                                expression: "remarksshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("备注：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索备注",
                                  size: "small",
                                },
                                on: { change: _vm.remarksClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.remarksClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.remarks,
                                  callback: function ($$v) {
                                    _vm.remarks = $$v
                                  },
                                  expression: "remarks",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.remarksClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("备注")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.attributionshow,
                                expression: "attributionshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("归属地：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索归属地",
                                  size: "small",
                                },
                                on: { change: _vm.attributionClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.attributionClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.attribution,
                                  callback: function ($$v) {
                                    _vm.attribution = $$v
                                  },
                                  expression: "attribution",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.attributionClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("归属地")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.finalFollowshow,
                                expression: "finalFollowshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("time-component", {
                              attrs: {
                                timevla: _vm.finalFollow,
                                titleT: "最后跟进日期",
                              },
                              on: {
                                FollowClick: _vm.FollowClick,
                                forkClick: _vm.forkClick,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.createnameshow,
                                expression: "createnameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索电话、名称",
                                  size: "small",
                                },
                                on: { change: _vm.createname },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.createname($event)
                                  },
                                },
                                model: {
                                  value: _vm.create_realname,
                                  callback: function ($$v) {
                                    _vm.create_realname = $$v
                                  },
                                  expression: "create_realname",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.createname },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.createtimeshow,
                                expression: "createtimeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("time-component", {
                              attrs: {
                                timevla: _vm.finalFollow,
                                titleT: "创建日期",
                              },
                              on: {
                                FollowClick: _vm.FollowClick,
                                forkClick: _vm.forkClick,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.noteAppendedshow,
                                expression: "noteAppendedshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建附注：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索创建附注",
                                  size: "small",
                                },
                                on: { change: _vm.noteAppendedClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.noteAppendedClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.noteAppended,
                                  callback: function ($$v) {
                                    _vm.noteAppended = $$v
                                  },
                                  expression: "noteAppended",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.noteAppendedClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建附注")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.structure_idshow,
                                expression: "structure_idshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("组织部门：")]
                            ),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.bumenoptions,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                "collapse-tags": "",
                                clearable: "",
                              },
                              on: { change: _vm.structureclick },
                              model: {
                                value: _vm.structure_id,
                                callback: function ($$v) {
                                  _vm.structure_id = $$v
                                },
                                expression: "structure_id",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("组织部门")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.labelpeersshow,
                                expression: "labelpeersshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("客户标签：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: { size: "small", placeholder: "请选择" },
                                on: { change: _vm.customerLabel },
                                model: {
                                  value: _vm.labelpeers,
                                  callback: function ($$v) {
                                    _vm.labelpeers = $$v
                                  },
                                  expression: "labelpeers",
                                },
                              },
                              _vm._l(_vm.labelpeersEs, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户标签")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nexttimeshow,
                                expression: "nexttimeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("下次回访时间：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.nextDateTime },
                              model: {
                                value: _vm.nexttime,
                                callback: function ($$v) {
                                  _vm.nexttime = $$v
                                },
                                expression: "nexttime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("下次回访日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ittnzyshow,
                                expression: "ittnzyshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("意向专业：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  size: "small",
                                  clearable: "",
                                },
                                on: { change: _vm.shouwittnzyclick },
                                model: {
                                  value: _vm.showittnzy,
                                  callback: function ($$v) {
                                    _vm.showittnzy = $$v
                                  },
                                  expression: "showittnzy",
                                },
                              },
                              _vm._l(_vm.Intendeds, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("意向专业")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.followshow,
                                expression: "followshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("最后流转人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loadmore",
                                    rawName: "v-loadmore",
                                    value: _vm.followName,
                                    expression: "followName",
                                  },
                                ],
                                ref: "clearValue",
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  size: "small",
                                  "collapse-tags": "",
                                  loading: _vm.loading,
                                  "remote-method": _vm.followMethod,
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.followidEs },
                                model: {
                                  value: _vm.follow_Id,
                                  callback: function ($$v) {
                                    _vm.follow_Id = $$v
                                  },
                                  expression: "follow_Id",
                                },
                              },
                              _vm._l(_vm.follow_Arr, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.realname,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后流转人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.revisit,
                                expression: "revisit",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("批量修改回访时间：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                type: "datetime",
                                size: "small",
                                "picker-options": _vm.pickerOptions,
                                "value-format": "timestamp",
                                placeholder: "选择日期时间",
                              },
                              on: { change: _vm.revisitlevelEs },
                              model: {
                                value: _vm.revisitlevel,
                                callback: function ($$v) {
                                  _vm.revisitlevel = $$v
                                },
                                expression: "revisitlevel",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.DeletesEs()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShow,
                                expression: "isShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("批量修改学员级别：")]
                            ),
                            _vm._v(" "),
                            _c("el-cascader", {
                              ref: "cascaderAddr",
                              staticStyle: { width: "250px" },
                              attrs: {
                                size: "small",
                                options: _vm.GetFieldInfodata2,
                              },
                              on: { change: _vm.PostMultipleUpdates },
                              model: {
                                value: _vm.Batchstudentlevel,
                                callback: function ($$v) {
                                  _vm.Batchstudentlevel = $$v
                                },
                                expression: "Batchstudentlevel",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletes("isShow")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowDataType,
                                expression: "isShowDataType",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("批量修改数据类型：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.changeDataTypeFun },
                                model: {
                                  value: _vm.batchDataType,
                                  callback: function ($$v) {
                                    _vm.batchDataType = $$v
                                  },
                                  expression: "batchDataType",
                                },
                              },
                              _vm._l(_vm.dataTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletes("isShowDataType")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.seaStimeShow,
                                expression: "seaStimeShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("进入私海日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.seaStimeClick },
                              model: {
                                value: _vm.seaStimeName,
                                callback: function ($$v) {
                                  _vm.seaStimeName = $$v
                                },
                                expression: "seaStimeName",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("进入私海日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.callNumShow,
                                expression: "callNumShow",
                              },
                            ],
                            staticClass: "call_num",
                            staticStyle: {
                              "margin-top": "15px",
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("el-col", { attrs: { span: 6 } }, [
                              _c(
                                "span",
                                { staticClass: "nameTitle projectScreening" },
                                [_vm._v("拨打次数：")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c("el-input", {
                                  attrs: { size: "small", type: "number" },
                                  model: {
                                    value: _vm.sNum,
                                    callback: function ($$v) {
                                      _vm.sNum = $$v
                                    },
                                    expression: "sNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              {
                                staticClass: "line",
                                staticStyle: { padding: "0", width: "8px" },
                                attrs: { span: 1 },
                              },
                              [_vm._v("-")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { size: "small", type: "number" },
                                    model: {
                                      value: _vm.eNum,
                                      callback: function ($$v) {
                                        _vm.eNum = $$v
                                      },
                                      expression: "eNum",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        slot: "append",
                                        icon: "el-icon-search",
                                      },
                                      on: { click: _vm.callNums },
                                      slot: "append",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("拨打次数")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.updateTimeShow,
                                expression: "updateTimeShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("最后更新日期：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.updateTimeClick },
                              model: {
                                value: _vm.updateTime,
                                callback: function ($$v) {
                                  _vm.updateTime = $$v
                                },
                                expression: "updateTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后更新日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comNameshow,
                                expression: "comNameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("推广项目：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广项目",
                                  size: "small",
                                },
                                on: { change: _vm.comNameClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comNameClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comName,
                                  callback: function ($$v) {
                                    _vm.comName = $$v
                                  },
                                  expression: "comName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comNameClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广项目")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comWebsiteshow,
                                expression: "comWebsiteshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("推广地址：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广地址",
                                  size: "small",
                                },
                                on: { change: _vm.comWebsiteClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comWebsiteClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comWebsite,
                                  callback: function ($$v) {
                                    _vm.comWebsite = $$v
                                  },
                                  expression: "comWebsite",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comWebsiteClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广地址")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.recoveryShow,
                                expression: "recoveryShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("预计回收时间：")]
                            ),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "250px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.recoveryDateTime },
                              model: {
                                value: _vm.recoveryTime,
                                callback: function ($$v) {
                                  _vm.recoveryTime = $$v
                                },
                                expression: "recoveryTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("预计回收时间")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.projectLiShow,
                                expression: "projectLiShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("报考项目：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.comWebsiteClick },
                                model: {
                                  value: _vm.projectTextId,
                                  callback: function ($$v) {
                                    _vm.projectTextId = $$v
                                  },
                                  expression: "projectTextId",
                                },
                              },
                              _vm._l(_vm.projects, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("报考项目")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        fit: "",
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F8F9FB",
                          color: "#222222",
                        },
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        key: 1,
                        attrs: {
                          type: "selection",
                          selectable: _vm.checkSelectable,
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _vm.displaySettings.includes("序号")
                        ? _c("el-table-column", {
                            key: 2,
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3946342523
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("置顶")
                        ? _c("el-table-column", {
                            key: 3,
                            attrs: { label: "置顶", align: "center" },
                            on: {
                              click: function ($event) {
                                return _vm.Activeshow()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.is_top == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "1",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("置顶")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                background: "#ffb400",
                                                "border-color": "#ffb400",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "0",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消置顶")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1419194492
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户名称")
                        ? _c("el-table-column", {
                            key: 4,
                            attrs: { label: "客户名称", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890ff" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTodetails(
                                                scope.row,
                                                scope
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.cname)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1568735955
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 5,
                              attrs: {
                                label: "创建日期",
                                sortable: "custom",
                                "min-width": "150",
                                width: "170",
                                prop: "create_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建日期")
                                      },
                                    },
                                  },
                                  [_vm._v("创建日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("电话号码")
                        ? _c("el-table-column", {
                            key: 6,
                            attrs: {
                              label: "电话号码",
                              prop: "mobile",
                              width: "135",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(scope.row.mobile) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3762696552
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地")
                        ? _c(
                            "el-table-column",
                            {
                              key: 51,
                              attrs: {
                                label: "归属地",
                                prop: "mobile_address",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("归属地")
                                      },
                                    },
                                  },
                                  [_vm._v("归属地")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("微信号码")
                        ? _c("el-table-column", {
                            key: 8,
                            attrs: {
                              label: "微信号码",
                              prop: "wxnum",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向专业")
                        ? _c(
                            "el-table-column",
                            {
                              key: 9,
                              attrs: {
                                label: "意向专业",
                                prop: "ittnzy",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("意向专业")
                                      },
                                    },
                                  },
                                  [_vm._v("意向专业")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人")
                        ? _c("el-table-column", {
                            key: 10,
                            attrs: {
                              label: "跟进人",
                              prop: "follow_realname",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后跟进日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 11,
                              attrs: {
                                label: "最后跟进日期",
                                sortable: "custom",
                                width: "160",
                                prop: "follw_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后跟进日期")
                                      },
                                    },
                                  },
                                  [_vm._v("最后跟进日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员级别")
                        ? _c("el-table-column", {
                            key: 12,
                            attrs: {
                              label: "学员级别",
                              width: "100",
                              prop: "customer_level",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学术成绩")
                        ? _c("el-table-column", {
                            key: 13,
                            attrs: {
                              label: "学术成绩",
                              prop: "academic_achievements",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进")
                        ? _c("el-table-column", {
                            key: 14,
                            attrs: {
                              sortable: "custom",
                              prop: "follow_num",
                              label: "跟进",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          key: 15,
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.followUpCustomers(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "跟进(" +
                                              _vm._s(scope.row.follow_num) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              844124618
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("下次回访日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 17,
                              attrs: {
                                label: "下次回访日期",
                                sortable: "custom",
                                width: "170",
                                prop: "next_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("下次回访日期")
                                      },
                                    },
                                  },
                                  [_vm._v("下次回访日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("报考项目")
                        ? _c(
                            "el-table-column",
                            {
                              key: 18,
                              attrs: {
                                label: "报考项目",
                                prop: "ittnxm",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("报考项目")
                                      },
                                    },
                                  },
                                  [_vm._v("报考项目")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户标签")
                        ? _c(
                            "el-table-column",
                            {
                              key: 19,
                              attrs: {
                                label: "客户标签",
                                prop: "labelpeer",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("客户标签")
                                      },
                                    },
                                  },
                                  [_vm._v("客户标签")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("撞单次数")
                        ? _c("el-table-column", {
                            key: 20,
                            attrs: {
                              label: "撞单次数",
                              prop: "zdnum",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("QQ号")
                        ? _c("el-table-column", {
                            key: 21,
                            attrs: {
                              label: "QQ号",
                              prop: "qq",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("年龄")
                        ? _c("el-table-column", {
                            key: 22,
                            attrs: {
                              label: "年龄",
                              prop: "age",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("备注")
                        ? _c(
                            "el-table-column",
                            {
                              key: 67,
                              attrs: {
                                width: "68",
                                label: "备注",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "itemDes",
                                            attrs: {
                                              content: scope.row.notes,
                                              "popper-class": "toolp",
                                              placement: "top-start",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "dib ell",
                                                staticStyle: { width: "48px" },
                                              },
                                              [_vm._v(_vm._s(scope.row.notes))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3724436026
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("备注")
                                      },
                                    },
                                  },
                                  [_vm._v("备注")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建附注")
                        ? _c(
                            "el-table-column",
                            {
                              key: 23,
                              attrs: { label: "创建附注", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "itemDes",
                                            attrs: {
                                              content: scope.row.create_notesE,
                                              "popper-class": "toolp",
                                              placement: "top-start",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c("span", { staticClass: "dib" }, [
                                              _vm._v(
                                                _vm._s(scope.row.create_notes)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1083207677
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建附注")
                                      },
                                    },
                                  },
                                  [_vm._v("创建附注")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("岗位")
                        ? _c("el-table-column", {
                            key: 24,
                            attrs: {
                              label: "岗位",
                              prop: "post",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话2")
                        ? _c("el-table-column", {
                            key: 25,
                            attrs: {
                              label: "联系电话2",
                              width: "110",
                              prop: "mobile1",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地2")
                        ? _c("el-table-column", {
                            key: 26,
                            attrs: {
                              label: "归属地2",
                              prop: "mobile1_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话3")
                        ? _c("el-table-column", {
                            key: 27,
                            attrs: {
                              label: "联系电话3",
                              width: "110",
                              prop: "mobile2",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地3")
                        ? _c("el-table-column", {
                            key: 28,
                            attrs: {
                              label: "归属地3",
                              prop: "mobile2_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向院校")
                        ? _c("el-table-column", {
                            key: 29,
                            attrs: {
                              label: "意向院校",
                              prop: "ittnyx",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("职业")
                        ? _c("el-table-column", {
                            key: 30,
                            attrs: {
                              label: "职业",
                              prop: "occupation",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员来源")
                        ? _c("el-table-column", {
                            key: 31,
                            attrs: {
                              label: "学员来源",
                              prop: "client_name",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("行业")
                        ? _c("el-table-column", {
                            key: 32,
                            attrs: {
                              label: "行业",
                              prop: "industry",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向简章")
                        ? _c("el-table-column", {
                            key: 33,
                            attrs: {
                              label: "意向简章",
                              prop: "ittnjz",
                              align: "center",
                              "max-height": "100",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("当前学历")
                        ? _c("el-table-column", {
                            key: 35,
                            attrs: {
                              label: "当前学历",
                              prop: "graduation_xueli",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("原始创建人")
                        ? _c("el-table-column", {
                            key: 36,
                            attrs: {
                              label: "原始创建人",
                              width: "110",
                              prop: "old_create_realname",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向地区")
                        ? _c("el-table-column", {
                            key: 37,
                            attrs: {
                              label: "意向地区",
                              prop: "ittngj",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("发起沟通网址")
                        ? _c("el-table-column", {
                            key: 38,
                            attrs: {
                              label: "发起沟通网址",
                              prop: "communication_website",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("语言成绩")
                        ? _c("el-table-column", {
                            key: 39,
                            attrs: {
                              label: "语言成绩",
                              prop: "language_achievement",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("预算")
                        ? _c("el-table-column", {
                            key: 40,
                            attrs: {
                              label: "预算",
                              prop: "budget",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("座机")
                        ? _c("el-table-column", {
                            key: 41,
                            attrs: {
                              label: "座机",
                              prop: "telephone",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业院校")
                        ? _c("el-table-column", {
                            key: 42,
                            attrs: {
                              label: "毕业院校",
                              prop: "graduate_school",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("组织部门")
                        ? _c(
                            "el-table-column",
                            {
                              key: 43,
                              attrs: {
                                label: "组织部门",
                                prop: "structure",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("组织部门")
                                      },
                                    },
                                  },
                                  [_vm._v("组织部门")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广项目")
                        ? _c(
                            "el-table-column",
                            {
                              key: 55,
                              attrs: {
                                label: "推广项目",
                                prop: "communication_name",
                                align: "center",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.communication_name.length > 30
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "itemDes",
                                                attrs: {
                                                  content:
                                                    scope.row
                                                      .communication_name,
                                                  "popper-class": "toolp",
                                                  placement: "top-start",
                                                  effect: "light",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.communication_name.slice(
                                                        0,
                                                        30
                                                      ) + "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.communication_name
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1452567585
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广项目")
                                      },
                                    },
                                  },
                                  [_vm._v("推广项目")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广地址")
                        ? _c(
                            "el-table-column",
                            {
                              key: 56,
                              attrs: {
                                label: "推广地址",
                                prop: "communication_website",
                                align: "center",
                                width: "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.communication_website.length >
                                        30
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "itemDes",
                                                attrs: {
                                                  content:
                                                    scope.row
                                                      .communication_website,
                                                  "popper-class": "toolp",
                                                  placement: "top-start",
                                                  effect: "light",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.communication_website.slice(
                                                        0,
                                                        30
                                                      ) + "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row
                                                    .communication_website
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1042608129
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广地址")
                                      },
                                    },
                                  },
                                  [_vm._v("推广地址")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("邮箱")
                        ? _c("el-table-column", {
                            key: 44,
                            attrs: {
                              label: "邮箱",
                              prop: "cemail",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业专业")
                        ? _c("el-table-column", {
                            key: 45,
                            attrs: {
                              label: "毕业专业",
                              prop: "graduation_major",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("护照号码")
                        ? _c("el-table-column", {
                            key: 46,
                            attrs: {
                              label: "护照号码",
                              prop: "passport_number",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后流转人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 47,
                              attrs: {
                                label: "最后流转人",
                                width: "100",
                                prop: "roam_realname",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后流转人")
                                      },
                                    },
                                  },
                                  [_vm._v("最后流转人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("进入私海日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 48,
                              attrs: {
                                label: "进入私海日期",
                                sortable: "custom",
                                width: "170",
                                prop: "sea_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("进入私海日期")
                                      },
                                    },
                                  },
                                  [_vm._v("进入私海日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后更新日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 49,
                              attrs: {
                                label: "最后更新日期",
                                sortable: "custom",
                                width: "170",
                                prop: "update_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后更新日期")
                                      },
                                    },
                                  },
                                  [_vm._v("最后更新日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("共享日期")
                        ? _c("el-table-column", {
                            key: 52,
                            attrs: { label: "共享日期", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人所属部门")
                        ? _c("el-table-column", {
                            key: 53,
                            attrs: { label: "跟进人所属部门", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("拨打次数")
                        ? _c(
                            "el-table-column",
                            {
                              key: 61,
                              attrs: {
                                label: "拨打次数",
                                sortable: "custom",
                                width: "120",
                                prop: "all_call_num",
                                align: "center",
                                filters: [],
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.all_call_num > 0
                                          ? _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  color: "#1890ff",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewRecord(
                                                      scope.row.customer_id,
                                                      scope.row.cname,
                                                      scope.row.mobile
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.all_call_num
                                                  ) + "次"
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.all_call_num) +
                                                  "次"
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4221931014
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("拨打次数")
                                      },
                                    },
                                  },
                                  [_vm._v("拨打次数")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("系统外呼")
                        ? _c("el-table-column", {
                            key: 58,
                            attrs: {
                              fixed: "right",
                              label: "系统外呼",
                              align: "center",
                              width: "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "点击可使用系统一键外呼",
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "28px",
                                              cursor: "pointer",
                                            },
                                            attrs: {
                                              src: require("@/assets/icon/SystemCall.png"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.OutboundClick(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              495184806
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("性别")
                        ? _c("el-table-column", {
                            key: 51,
                            attrs: { label: "性别", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.sex == "0" ? "男" : "女"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4079631929
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("预计回收时间")
                        ? _c(
                            "el-table-column",
                            {
                              key: 59,
                              attrs: {
                                label: "预计回收时间",
                                sortable: "custom",
                                width: "170",
                                prop: "recovery_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("预计回收时间")
                                      },
                                    },
                                  },
                                  [_vm._v("预计回收时间")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("手机操作")
                        ? _c("el-table-column", {
                            key: 80,
                            attrs: {
                              fixed: "right",
                              label: "手机操作",
                              align: "center",
                              width: "130",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("wechat-dialog", {
                                            attrs: { "row-data": scope.row },
                                            on: {
                                              onUpdateQueryFun:
                                                _vm.onUpdateQueryFun,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  "点击可使用工作手机一键外呼",
                                                placement: "bottom-start",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "28px",
                                                  height: "auto",
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  src: require("@/assets/icon/dial.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.WorkCall(
                                                      scope.row,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              directives: [
                                                {
                                                  name: "permission",
                                                  rawName: "v-permission",
                                                  value: ["发送短信"],
                                                  expression: "['发送短信']",
                                                },
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    scope.row.sms_switch == 1,
                                                  expression:
                                                    "scope.row.sms_switch == 1",
                                                },
                                              ],
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "点击发送短信",
                                                placement: "bottom-start",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "28px",
                                                  height: "auto",
                                                  cursor: "pointer",
                                                },
                                                attrs: {
                                                  src: require("@/assets/icon/send-msg.png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendMsgClickFun(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1546813788
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c("mailList", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radio,
                condition: _vm.condition,
                visible: _vm.mailListVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisible = $event
                },
                maillistRadio: _vm.maillistRadio,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
            _vm._v(" "),
            _c("mailListUp", {
              ref: "staffSelect",
              attrs: {
                getType: _vm.get_type,
                radio: _vm.radioUp,
                condition: _vm.condition,
                visible: _vm.mailListVisibleUp,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleUp = $event
                },
                SharedSustomersUp: _vm.SharedSustomersUp,
              },
              model: {
                value: _vm.staffResultUp,
                callback: function ($$v) {
                  _vm.staffResultUp = $$v
                },
                expression: "staffResultUp",
              },
            }),
            _vm._v(" "),
            _c("mailListEs", {
              ref: "staffSelect",
              attrs: {
                radio: _vm.radioEs,
                condition: _vm.condition,
                visible: _vm.mailListVisibleEs,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleEs = $event
                },
                staffSelectConfirmEs: _vm.staffSelectConfirmEs,
              },
              model: {
                value: _vm.staffResultEs,
                callback: function ($$v) {
                  _vm.staffResultEs = $$v
                },
                expression: "staffResultEs",
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialogs",
                attrs: { title: "跟进", visible: _vm.followUp },
                on: {
                  "update:visible": function ($event) {
                    _vm.followUp = $event
                  },
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              staticStyle: {
                                width: "400px",
                                "margin-left": "50px",
                              },
                              attrs: {
                                "label-position": "right",
                                "label-width": "130px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "修改学员级别:" } },
                                [
                                  _c("el-cascader", {
                                    ref: "cascaderAddr",
                                    staticStyle: { width: "100%" },
                                    attrs: { options: _vm.GetFieldInfodata2 },
                                    on: { change: _vm.PostMultipleUpdates },
                                    model: {
                                      value: _vm.Batchstudentlevel,
                                      callback: function ($$v) {
                                        _vm.Batchstudentlevel = $$v
                                      },
                                      expression: "Batchstudentlevel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户名称:" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.followUpEs.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.followUpEs, "name", $$v)
                                      },
                                      expression: "followUpEs.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "主题:" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.followUpEs.theme,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.followUpEs, "theme", $$v)
                                      },
                                      expression: "followUpEs.theme",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "类型:" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "Please select" },
                                      model: {
                                        value: _vm.followUpEs.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.followUpEs, "type", $$v)
                                        },
                                        expression: "followUpEs.type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.calendarTypeOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.label,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "下次回访日期:" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "default-value": new Date(),
                                      type: "datetime",
                                      placeholder: "选择日期时间",
                                      "value-format": "timestamp",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.getUnfollowmsg },
                                    model: {
                                      value: _vm.followUpEs.next_time,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.followUpEs,
                                          "next_time",
                                          $$v
                                        )
                                      },
                                      expression: "followUpEs.next_time",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.Unfollowshow,
                                          expression: "Unfollowshow",
                                        },
                                      ],
                                      staticStyle: { color: "red" },
                                    },
                                    [
                                      _vm._v(
                                        "当天已有" +
                                          _vm._s(_vm.Unfollow) +
                                          "条待回访数据"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: { "text-align": "center" },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.RecordSure } },
                                [_vm._v("取消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.NewFollowupRecordSure },
                                },
                                [_vm._v("确认")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("h3", [_vm._v("历史跟进记录:")]),
                        _vm._v(" "),
                        _c(
                          "el-timeline",
                          _vm._l(_vm.activities, function (activity, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  placement: "top",
                                  timestamp: activity.create_time,
                                },
                              },
                              [
                                _c("span", { staticClass: "eD8CF0 mr10" }, [
                                  _vm._v(_vm._s(activity.type)),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(activity.create_realnaem)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "下次回访日期：" +
                                      _vm._s(activity.next_time)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(activity.content))]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "提示",
                  visible: _vm.dialogVisible,
                  width: "30%",
                  center: "",
                  showClose: false,
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                },
              },
              [_c("span", [_vm._v("正在上传,请稍等......")])]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "bleEs",
                attrs: { visible: _vm.dialogPvVisibleEs, title: "外送项目方" },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPvVisibleEs = $event
                  },
                  close: _vm.closeDialog,
                },
              },
              [
                _c("span", [_vm._v("选择外送的项目方：")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "40%" },
                    attrs: {
                      multiple: "",
                      filterable: "",
                      size: "small",
                      "loading-text": "加载中",
                      placeholder: "请选择外送的项目方",
                    },
                    on: { change: _vm.deliveryClick },
                    model: {
                      value: _vm.side,
                      callback: function ($$v) {
                        _vm.side = $$v
                      },
                      expression: "side",
                    },
                  },
                  _vm._l(_vm.optionsEs, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.title, value: item.id },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.deliveryShow
                  ? _c(
                      "div",
                      [
                        _c("span", [_vm._v("选择外送的城市：    ")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "40%" },
                            attrs: {
                              size: "small",
                              "loading-text": "加载中",
                              placeholder: "请选择外送的城市",
                            },
                            on: { change: _vm.deliveryCityClick },
                            model: {
                              value: _vm.deliveryCityVal,
                              callback: function ($$v) {
                                _vm.deliveryCityVal = $$v
                              },
                              expression: "deliveryCityVal",
                            },
                          },
                          _vm._l(_vm.cityArr, function (item) {
                            return _c("el-option", {
                              key: item.city_id,
                              attrs: {
                                label: item.city_name,
                                value: item.city_id,
                              },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", [_vm._v("选择外送的项目：    ")]),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "40%" },
                            attrs: {
                              size: "small",
                              disabled: _vm.ProjectShow3,
                              "loading-text": "加载中",
                              placeholder: "请选择外送的项目",
                            },
                            model: {
                              value: _vm.deliveryProjectVal,
                              callback: function ($$v) {
                                _vm.deliveryProjectVal = $$v
                              },
                              expression: "deliveryProjectVal",
                            },
                          },
                          _vm._l(_vm.projectArr, function (item) {
                            return _c("el-option", {
                              key: item.project_type,
                              attrs: {
                                label: item.project_name,
                                value: item.project_type,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-top": "5%" },
                    attrs: {
                      data: _vm.projectSide,
                      border: "",
                      fit: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "cname",
                        align: "center",
                        label: "学员名称",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "mobile",
                        align: "center",
                        label: "学员电话",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "创建附注", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "itemDes",
                                    attrs: {
                                      content: scope.row.create_notesE,
                                      "popper-class": "toolp",
                                      placement: "top-start",
                                      effect: "light",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "dib" }, [
                                      _vm._v(_vm._s(scope.row.create_notes)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1083207677
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "当前学历",
                        align: "center",
                        prop: "graduation_xueli",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "意向地区",
                        align: "center",
                        prop: "ittngj",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "realname",
                        align: "center",
                        label: "性别",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.sex == "0" ? "男" : "女")
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4079631929
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "age", label: "年龄", align: "center" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.delivery },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.dialogPvVisibleDill,
                  title: "提示",
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPvVisibleDill = $event
                  },
                },
              },
              [
                _vm.theDillEs_Even
                  ? _c(
                      "div",
                      { staticClass: "theDill_list" },
                      [
                        _c("p", { staticClass: "title" }, [
                          _vm._v("外送撞单提示"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.theDillEs, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "theDill" },
                            [
                              _c("p", { staticStyle: { color: "#ff6f6f" } }, [
                                _vm._v(
                                  "\n                        项目方\n                        "
                                ),
                                _c("span", [_vm._v(_vm._s(item.qname))]),
                                _vm._v(
                                  " 已存在该客户信息，禁止重复报备\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(item.list, function (event, keyEs) {
                                return _c(
                                  "div",
                                  {
                                    key: keyEs,
                                    staticClass: "theDill_Information",
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "\n                            客户名称：\n                            "
                                      ),
                                      _c("span", [_vm._v(_vm._s(event.canme))]),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                            客户电话：\n                            "
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(event.mobile)),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                            录入时间：\n                            "
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(event.create_time)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.repeat_listEven
                  ? _c(
                      "div",
                      { staticClass: "repeat" },
                      [
                        _c("p", { staticClass: "title" }, [_vm._v("外送提示")]),
                        _vm._v(" "),
                        _vm._l(_vm.repeat_listEs, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "repeat_list" },
                            [_c("p", [_vm._v(_vm._s(item))])]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.repeat_Import
                  ? _c(
                      "div",
                      { staticClass: "repeat" },
                      [
                        _c("p", { staticClass: "title" }, [_vm._v("导入提示")]),
                        _vm._v(" "),
                        _vm._l(_vm.Import_listEs, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "repeat_list" },
                            [_c("p", [_vm._v(_vm._s(item))])]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.cancelDill },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "waisl",
                attrs: {
                  title: "新建客户",
                  visible: _vm.dialogBuild,
                  width: "30%",
                  showClose: false,
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogBuild = $event
                  },
                  close: _vm.dialogFork,
                },
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "新建客户是否为外送客户：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "请选择" },
                            on: { change: _vm.DeliveryToCli },
                            model: {
                              value: _vm.DeliveryTo,
                              callback: function ($$v) {
                                _vm.DeliveryTo = $$v
                              },
                              expression: "DeliveryTo",
                            },
                          },
                          _vm._l(_vm.DeliveryToCustomers, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showIf
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的项目方名称：" } },
                          [
                            _c(
                              "el-select",
                              {
                                ref: "selectCh",
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  multiple: "",
                                  filterable: "",
                                  size: "small",
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.EntryName },
                                model: {
                                  value: _vm.side,
                                  callback: function ($$v) {
                                    _vm.side = $$v
                                  },
                                  expression: "side",
                                },
                              },
                              _vm._l(_vm.optionsEs, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showIfs
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的城市：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  size: "small",
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.cityClick },
                                model: {
                                  value: _vm.cityVal,
                                  callback: function ($$v) {
                                    _vm.cityVal = $$v
                                  },
                                  expression: "cityVal",
                                },
                              },
                              _vm._l(_vm.cityArr, function (item) {
                                return _c("el-option", {
                                  key: item.city_id,
                                  attrs: {
                                    label: item.city_name,
                                    value: item.city_id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showIfs
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的项目：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  size: "small",
                                  "collapse-tags": "",
                                  disabled: _vm.ProjectShow2,
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.projectVal,
                                  callback: function ($$v) {
                                    _vm.projectVal = $$v
                                  },
                                  expression: "projectVal",
                                },
                              },
                              _vm._l(_vm.projectArr, function (item) {
                                return _c("el-option", {
                                  key: item.project_type,
                                  attrs: {
                                    label: item.project_name,
                                    value: item.project_type,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.dialogClick } }, [
                      _vm._v("取消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.NewCustomer(1)
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "waisl",
                attrs: {
                  title: "导入客户",
                  visible: _vm.dialogBuildEs,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogBuildEs = $event
                  },
                  close: _vm.dialogFork,
                },
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "导入客户是否为外送客户：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "small", placeholder: "请选择" },
                            on: { change: _vm.DeliveryToCliEs },
                            model: {
                              value: _vm.DeliveryToEs,
                              callback: function ($$v) {
                                _vm.DeliveryToEs = $$v
                              },
                              expression: "DeliveryToEs",
                            },
                          },
                          _vm._l(_vm.DeliveryToCustomersEs, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.label, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showIfEs
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的项目方名称：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  multiple: "",
                                  size: "small",
                                  "collapse-tags": "",
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.EntryNames },
                                model: {
                                  value: _vm.sideEs,
                                  callback: function ($$v) {
                                    _vm.sideEs = $$v
                                  },
                                  expression: "sideEs",
                                },
                              },
                              _vm._l(_vm.optionsEseven, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showIfEi
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的城市：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  size: "small",
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.ImportCityClick },
                                model: {
                                  value: _vm.ImportCityVal,
                                  callback: function ($$v) {
                                    _vm.ImportCityVal = $$v
                                  },
                                  expression: "ImportCityVal",
                                },
                              },
                              _vm._l(_vm.cityArr, function (item) {
                                return _c("el-option", {
                                  key: item.city_id,
                                  attrs: {
                                    label: item.city_name,
                                    value: item.city_id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showIfEi
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "选择外送的项目：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "3.5%" },
                                attrs: {
                                  size: "small",
                                  "collapse-tags": "",
                                  disabled: _vm.ProjectShow,
                                  "loading-text": "加载中",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.ImportProjectVal,
                                  callback: function ($$v) {
                                    _vm.ImportProjectVal = $$v
                                  },
                                  expression: "ImportProjectVal",
                                },
                              },
                              _vm._l(_vm.projectArr, function (item) {
                                return _c("el-option", {
                                  key: item.project_type,
                                  attrs: {
                                    label: item.project_name,
                                    value: item.project_type,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          "http-request": _vm.TeacherExcel,
                          "show-file-list": true,
                          action: "action",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "update"),
                                  (_vm.dialogFormVisible = true)
                              },
                            },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title:
                    "累计拨打记录 ( " +
                    _vm.cusName +
                    "：" +
                    _vm.cusPhone +
                    " )",
                  visible: _vm.dialogTableVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogTableVisible = $event
                  },
                },
              },
              [
                _vm.dialogTableVisible
                  ? _c("callRecord", { attrs: { cid: _vm.callCid } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("mobile-dialog", {
              ref: "sendmsg",
              on: { onUpdateQueryFun: _vm.onUpdateQueryFun },
            }),
            _vm._v(" "),
            _vm.showCustomerWrapper
              ? _c(
                  "div",
                  [
                    _c(
                      "el-dialog",
                      {
                        staticClass: "big-dialogs",
                        attrs: {
                          title: "客户详情",
                          width: "100%",
                          visible: _vm.showCustomerDetail,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.showCustomerDetail = $event
                          },
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c("subCustomerDetail", {
                              attrs: {
                                "customer-deatail-data":
                                  _vm.customerDeatailData,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { School, Majorlist, getIttnxm as _getIttnxm, FollwupAdd, Customerinfo, getLabelpeer, FollwupIndex as _FollwupIndex, getEntrance as _getEntrance, BrochuresList as _BrochuresList, MyShareSetshare, MyCustomerIndex, MyCustomerUpdate, getCustomerLevel, getGraduationMajor, agentFlowerNameList, MyCustomerRegression, MyCustomerCustomerlog, MyCustomerTransfoll, MyCustomerTransfer, orderlist, callout, callNum, unlink, mobilePhone, GetPhoneAddress, addWeChatFriend } from '@/api/ceshi_xiugai/whole';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import { getUnfollow } from '@/api/PublicAPI';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import mailList from '@/components/StaffSelect/mailListRadio';
import { getInfo } from '@/api/login';
import { addCallLog } from '@/api/ceshi_xiugai2/myproject';
import callRecord from '@/components/callRecord/index';
import mobileDialog from "./mobileDialog";
import wechatDialog from "./wechatDialog";
export default {
  props: {
    customerDeatailData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    mailListEs: mailListEs,
    mailListUp: mailListUp,
    mailList: mailList,
    callRecord: callRecord,
    mobileDialog: mobileDialog,
    wechatDialog: wechatDialog
  },
  data: function data() {
    return {
      // detaiData: {},
      bindTel: '',
      col: '',
      hsg: '',
      calls: '',
      action: this.$route.query.action,
      MobileAddress: '',
      diaShow: false,
      shangsj: true,
      ksjhag: false,
      Mantle: false,
      visisedbles: false,
      instance: '',
      tinetToolbarDom: '',
      tinetOptions: {
        showHold: false,
        showConsult: true,
        showTransfer: true,
        showSatisfaction: true,
        showMute: true
      },
      ksjfs: false,
      CallShow: false,
      bindTelShow: false,
      sum_money: '',
      //成交金额
      OutboundShow: true,
      staffResultUp: [],
      //转移跟进人
      radioUp: false,
      //转移跟进人
      mailListVisibleUp: false,
      Unfollow: '',
      //下次会发条数
      get_type: 0,
      Unfollowshow: false,
      //禁止改
      disable: false,
      wxdisable: false,
      tgmcdisable: false,
      tgdzdisable: false,
      mo1disable: false,
      mo2disable: false,
      noteApp: false,
      distribution: this.$route.query.distribution == 2 || this.$route.query.distribution == 3 ? true : false,
      customer_id: this.customerDeatailData.Cid,
      customer_data_id: this.customerDeatailData.CData_id,
      editRole: this.customerDeatailData.editRole,
      pageTop: this.customerDeatailData.page,
      pagesizeTop: this.customerDeatailData.pagesize,
      keyTop: this.customerDeatailData.key,
      Search: this.$route.query.Search,
      Student: this.$route.query.customer_level,
      list_typeEs: this.customerDeatailData.list_type,
      create: '',
      edite: '',
      activeNames: ['0'],
      //默认收齐状态
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      staffResultEs: [],
      radioEs: false,
      mailListVisibleEs: false,
      reverse: true,
      isNullCphone: false,
      isNullwxnum: false,
      isNullCphonetwo: false,
      dialogFormVisible: false,
      isNullCphonethree: false,
      isusertype: false,
      loading: false,
      radios: false,
      show2: false,
      show3: false,
      regression: true,
      //公海、私海
      mailListVisible: false,
      //创建人通讯录开关
      staffResult: [],
      //共享通讯录选取的值
      condition: [],
      page: 1,
      //页码
      pagesize: 15,
      //条数
      isusertypeName: '查看全部',
      journal: [],
      enteredBy: '',
      Intended: '',
      //意向专业name值
      General: '',
      //意向简章name值
      ins: '',
      //意向院校name值
      IntendedStation: [],
      //意向专业中转站
      IntendedMajor: [],
      GeneralStation: [],
      //意向简章中转站
      GeneralRules: [],
      instituStation: [],
      //意向院校中转站
      institu: [],
      flowerNumber: [],
      //花名
      entranceTextEs: '',
      flowerNumberStation: [],
      //花名中转站
      detailForm: {
        name: '',
        //名称
        flower_name: '',
        //花名
        flowerNameId: 0,
        //花名id
        category: '',
        //类别
        agent_num: '',
        //花名码
        Telephone: '',
        //电话1
        cphoneAdress: '',
        //归属地1
        Telephone2: '',
        //电话2
        cphoneAdress2: '',
        //归属地2
        Telephone3: '',
        //电话3
        cphoneAdress3: '',
        //归属地3
        mailbox: '',
        //邮箱
        QQ: '',
        //qq
        remarks: '',
        //备注
        appended: '',
        WeChat: '',
        //微信
        extensionName: '',
        //推广名称
        extensionAddress: '',
        //推广地址
        educationText: '',
        //当前学历
        areaOfInterest: '',
        //意向地区
        projectTextId: '',
        //报考项目id
        projectText: '',
        //报考项目
        GraduationMajor: '',
        //毕业专业
        IntendedMajor: [],
        //意向专业
        GeneralRules: [],
        //意向简章
        GraduatedFrom: '',
        //毕业院校
        institu: [],
        //意向院校所选的值
        budget: '',
        //预算
        sex: '',
        //性别
        entranceText: '',
        //预计入学id
        entranceTexts: '',
        //预计入学val
        post: '',
        //岗位
        achievements: '',
        //学术成绩
        Age: '',
        //年龄
        Landline: '',
        //座机
        occupation: '',
        //单位
        language: '',
        //语言成绩
        industry: '',
        //行业
        Passport: '',
        //护照号
        // communication: '', //发起沟通的网址
        hobby: '',
        //性格
        address: '',
        //详细地址
        labelpeer: [],
        //客户标签
        Student: [] //学员级别
      },
      message: '',
      wechat_account_id: '',
      baokaixiangmu: '',
      educationText: '',
      detailFormShow: {
        cname: true,
        intentionally: true,
        cphone: true,
        agent_name: true,
        area: true,
        noteAppEs: true,
        adress: true,
        note: true,
        wxnum: true,
        communication_name: true,
        communication_website: true,
        telephone: true,
        cemail: true,
        qq: true,
        cphonetwo: true,
        cphonethree: true,
        ittnzy: true,
        ittnzyShow: true,
        ittnxl: true,
        ittnxlShow: true,
        ittnyx: true,
        ittnyxShow: true,
        ittnxm: true,
        ittnxmShow: true,
        ittngj: true,
        budget: true,
        xuel: true,
        graduate: true,
        graduatezy: true,
        xueshuchengji: true,
        yuyanchengji: true,
        timeline: true,
        sex: true,
        age: true,
        station: true,
        industry: true,
        character: true,
        occupation: true,
        hzID: true,
        hobby: true,
        tolink: true,
        next_follow: true,
        groupName: true,
        groupNameShow: true,
        callNum: ''
      },
      form: {
        next_follow: '',
        //下次回访日期
        theme: '',
        typese: '1'
      },
      rules: {
        // theme: [{ required: true, message: '主题不能为空', trigger: 'blur' }],
      },
      flower_Array: [],
      //花名
      Intendeds: [],
      //意向专业
      GenalRules: [],
      //意向简章
      chatmodes: [],
      //意向院校
      activities: [],
      // 跟进记录
      projects: [],
      //报考项目
      entrance: [],
      //入学时间
      educations: [],
      //当前学历
      StudentLevel: [],
      //学员级别
      customer: [],
      //客户标签
      transferorid: '',
      //共享人的userid
      radio: false,
      tableData: [],
      //成交订单列表
      total: 0,
      //总条数
      charge_type: '',
      //用户对应组织架构类型
      orderPagesize: 10,
      orderPage: 1,
      activeName: 'customerInfo',
      detailListShow: false,
      addOrderShow: false,
      editOrderShow: false,
      orderDetailShow: false,
      real_mobile: '',
      staste: '',
      tians: true,
      customer_level: '',
      dialogTableVisible: false,
      has_approval_flow: false,
      // 用于存储修改后的手机号码，如果这个手机号没有修改 就不传手机号
      plaintext_mobile: '-1',
      plaintext_mobile_one: '-1',
      plaintext_mobile_two: '-1',
      // 数据类型下拉值
      dataTypeOptions: [{
        id: 8888,
        value: '',
        label: '请选择',
        disabled: true
      }, {
        id: 1,
        value: 1,
        label: '重庆留学'
      }, {
        id: 2,
        value: 2,
        label: '社科高级'
      }, {
        id: 3,
        value: 3,
        label: '外送数据'
      }],
      is_data_type: '',
      distribution_land_status: '',
      flag: '',
      platform_name: '',
      page_id: '',
      client_id: this.$store.state.user.client_id,
      wechatComponentData: {},
      sms_switch: '',
      detaiData: {}
    };
  },
  created: function created() {
    var _this2 = this;
    getInfo().then(function (res) {
      _this2.charge_type = res.data.charge_type;
    });
  },
  watch: {
    customerDeatailData: {
      deep: true,
      //深度监听
      immediate: true,
      handler: function handler(val) {
        this.detaiData = val;
        this.customer_id = val.Cid;
        this.customer_data_id = val.CData_id;
        this.editRole = val.editRole;
        this.pageTop = val.page;
        this.pagesizeTop = val.pagesize;
        this.keyTop = val.key;
        this.list_typeEs = val.list_type;
        this.init();
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;
    var that = this;
    document.removeEventListener('visibilitychange', that.handleVisiable);
    window.removeEventListener('beforeunload', function (e) {
      return _this3.beforeunloadHandler(e);
    });
    var baseUrl = process.env.VUE_APP_BASE_API3;
    var socket = io(baseUrl);
    socket.on('new_msg', function (msg) {
      var fixMsg = JSON.parse(msg.replace(/&quot;/g, '"'));
      if (fixMsg.type === 'work_phone_dial') {
        that.CallShow = false;
        localStorage.setItem('WorkPhones', 'null');
      }
    });
    document.addEventListener('visibilitychange', that.handleVisiable);
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.diaShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.diaShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
    var WorkPhones = localStorage.getItem('WorkPhones');
    if (WorkPhones == 'Calling') {
      this.CallShow = true;
    } else {
      this.CallShow = false;
    }
    this.create = this.$route.query.create;
    this.edite = this.$route.query.edit;
    this.bindTel = this.$store.getters.TianRun.bindTel.toString();
    this.col = this.$store.getters.TianRun.cno;
    if (this.bindTel != '') {
      this.bindTelShow = true;
    } else {
      this.bindTelShow = false;
    }
    // this.GetDetails(this.customer_id, this.customer_data_id);
    // this.Customerlog(this.customer_id, this.customer_data_id);
    // this.showTab();

    // this.dealOrder();

    // this.disabledShow();
    window.addEventListener('beforeunload', function (e) {
      return _this3.beforeunloadHandler(e);
    });
    // this.tianrun();
    // setInterval(() => {
    //     let tian = localStorage.getItem('Clink');
    //     if (tian == 'BUSY') {
    //         this.shangsj = false;
    //     } else if (tian == 'IDLE') {
    //         this.shangsj = true;
    //     }
    // }, 1000);
    // this.labelpeer();
    // this.FollwupIndex(this.customer_id, this.customer_data_id);
    // this.getCustomerLevelEs();
    //进入页面需要加载下拉的数据
    // getGraduationMajor().then((res) => {
    //     res.data.map((item) => {
    //         item.id = String(item.id);
    //     });
    //     this.educations = res.data;
    // });
    // this.getIttnxm();
  },
  filters: {
    pay_purpose: function pay_purpose(num) {
      if (num == 1) {
        return '报名费';
      } else if (num == 2) {
        return '定金';
      } else if (num == 3) {
        return '学费';
      } else if (num == 4) {
        return '资料费';
      } else if (num == 5) {
        return '语言培训费';
      } else if (num == 6) {
        return '普通留学申请费';
      }
    },
    periodization: function periodization(num) {
      if (num == 1) {
        return '一次性全款';
      } else if (num == 2) {
        return '分两次全款';
      } else if (num == 3) {
        return '分三次全款';
      } else if (num == 4) {
        return '分四次全款';
      }
    },
    periodization_set: function periodization_set(num) {
      if (num == 1) {
        return '第一次付款';
      } else if (num == 2) {
        return '第二次付款';
      } else if (num == 3) {
        return '第三次付款';
      } else if (num == 4) {
        return '第四次付款';
      } else if (num == 5) {
        return '一次性付全款';
      }
    }
  },
  methods: {
    init: function init() {
      var _this4 = this;
      this.GetDetails(this.customer_id, this.customer_data_id);
      this.Customerlog(this.customer_id, this.customer_data_id);
      this.showTab();
      this.disabledShow();
      this.tianrun();
      setInterval(function () {
        var tian = localStorage.getItem('Clink');
        if (tian == 'BUSY') {
          _this4.shangsj = false;
        } else if (tian == 'IDLE') {
          _this4.shangsj = true;
        }
      }, 1000);
      this.labelpeer();
      this.FollwupIndex(this.customer_id, this.customer_data_id);
      this.getCustomerLevelEs();

      //进入页面需要加载下拉的数据
      getGraduationMajor().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this4.educations = res.data;
      });
      this.getIttnxm();
    },
    handleVisiable: function handleVisiable(e) {
      //页面可见执行方法
      if (e.target.visibilityState === 'visible') {
        var WorkPhones = localStorage.getItem('WorkPhones');
        if (WorkPhones == 'Calling') {
          this.CallShow = true;
        } else {
          this.CallShow = false;
        }
      }
    },
    // 工作手机拨打
    WorkCall: function WorkCall() {
      var tian = localStorage.getItem('Clink');
      if (tian == 'BUSY') {
        this.CallShow = false;
      }
      // 调用子组件事件
      var row = _objectSpread({
        customer_id: this.customer_id
      }, this.wechatComponentData);
      // this.$refs.sendmsg.WorkCall(row);
      // 调用子组件事件
      this.$refs.sendmsg.OutboundClick(row, 'workphone');
    },
    // 点击发送短信
    sendMsgClickFun: function sendMsgClickFun() {
      // 调用子组件事件
      var row = _objectSpread({
        customer_id: this.customer_id
      }, this.wechatComponentData);
      this.$refs.sendmsg.isSendOrShowFun(row);
    },
    CancelAdding: function CancelAdding() {
      document.body.click();
    },
    DialogClick: function DialogClick() {
      this.diaShow = false;
    },
    beforeunloadHandler: function beforeunloadHandler(e) {
      localStorage.setItem('state', 'IDLE');
    },
    tianrun: function tianrun() {
      var _this5 = this;
      var stose = this.$store.getters.TianRun;
      if (stose.bindTel != '') {
        this.ksjhag = true;
        setInterval(function () {
          var state1 = localStorage.getItem('Clink');
          if (state1 == 'BUSY') {
            _this5.OutboundShow = false;
          } else if (state1 == 'IDLE') {
            _this5.OutboundShow = true;
          }
        }, 1000);
      } else {
        this.ksjhag = false;
      }
    },
    viewRecord: function viewRecord() {
      this.dialogTableVisible = true;
    },
    OutboundClick: function OutboundClick() {
      // 调用子组件事件
      var row = _objectSpread({
        customer_id: this.customer_id
      }, this.wechatComponentData);
      // this.$refs.sendmsg.OutboundClick(row, this.col);
      // 调用子组件事件
      this.$refs.sendmsg.OutboundClick(row, 'callout', this.col);
    },
    HangUpClick: function HangUpClick() {
      var _this6 = this;
      unlink({
        cno: this.col
      }).then(function (res) {
        _this6.OutboundShow = true;
        qiConnect.bye();
      });
    },
    showTab: function showTab() {
      var _this7 = this;
      var activeTab = localStorage.getItem('activeName');
      if (activeTab) {
        this.activeName = activeTab;
        var data = {
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          pagesize: this.orderPagesize,
          page: this.orderPage
        };
        orderlist(data).then(function (res) {
          _this7.tableData = res.data.data;
          _this7.total = res.data.total;
        });
      }
      localStorage.setItem('activeName', '');
    },
    disabledShow: function disabledShow() {
      var detailList = localStorage.getItem('detailList');
      var addOrder = localStorage.getItem('addOrder');
      var editOrder = localStorage.getItem('editOrder');
      var orderDetail = localStorage.getItem('orderDetail');
      if (detailList) {
        this.detailListShow = true;
      }
      if (addOrder) {
        this.addOrderShow = true;
      }
      if (editOrder) {
        this.editOrderShow = true;
      }
      if (orderDetail) {
        this.orderDetailShow = true;
      }
    },
    // 删除空数组方法
    removeByValue: function removeByValue(arr, attr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i][attr] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    addOrder: function addOrder(val, sign) {
      if (!this.has_approval_flow) {
        this.$confirm('您所在部门没有创建审批流，请联系系统管理员创建审批流后再提交成交信息', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        });
      } else {
        var data = {
          customer_id: this.customer_id,
          customer_data_id: this.customer_data_id,
          create: this.create,
          edite: this.edite,
          id: val,
          edit: sign
        };
        this.$router.push({
          path: '/orderInfo',
          query: data
        });
      }
    },
    GetDetails: function GetDetails(customer_id, customer_data_id) {
      var _this8 = this;
      //编辑获取详情
      Customerinfo({
        customer_id: customer_id,
        customer_data_id: customer_data_id
      }).then(function (res) {
        var data = res.data;
        _this8.customer_level = data.customer_level;
        _this8.customer_id = res.data.customer_id;
        _this8.has_approval_flow = data.has_approval_flow;
        if (data.create_id != _this8.$store.getters.id) {
          _this8.noteApp = true;
        }
        _this8.wechat_account_id = data.wechat_account_id;
        if (data.create_id != _this8.$store.getters.id) {
          _this8.tgmcdisable = true;
        }
        if (data.create_id != _this8.$store.getters.id) {
          _this8.tgdzdisable = true;
        }
        if (data.dial_mobile_switch == 1) {
          _this8.ksjfs = true;
        } else {
          _this8.ksjfs = false;
        }
        //成交金额
        var money = '' + data.sum_finished_money;
        if (money.indexOf('.') != -1) {
          _this8.sum_money = money;
        } else {
          _this8.sum_money = money + '.00';
        }
        localStorage.setItem('sum_money', _this8.sum_money);
        _this8.disable = data.source_client_id !== '' ? true : false;
        _this8.detailForm.appended = data.create_notes;
        //意向专业
        _this8.detailForm.IntendedMajor = [];
        _this8.IntendedMajor = [];
        data.ittnzy.forEach(function (item) {
          _this8.IntendedMajor.push(item);
          _this8.Intended = item.title.toString();
          _this8.detailForm.IntendedMajor.push(item.id);
        });
        // this.majorDedMaj();
        //意向简章
        _this8.GeneralRules = [];
        _this8.detailForm.GeneralRules = [];
        data.ittnjz.forEach(function (item) {
          _this8.GeneralRules.push(item);
          _this8.General = item.title.toString();
          _this8.detailForm.GeneralRules.push(item.id);
        });
        // this.BrochuresList();
        // 意向院校
        _this8.institu = [];
        _this8.detailForm.institu = [];
        data.ittnyx.forEach(function (item) {
          _this8.institu.push(item);
          _this8.ins = item.title.toString();
          _this8.detailForm.institu.push(item.id);
        });
        // this.SchoolDer();
        // 花名
        _this8.flowerNumber = [];
        // this.detailForm.flowerNameId = item.id
        if (data.source_client_id !== '') {
          _this8.flower_Array = data.source_client_info;
          data.source_client_info.forEach(function (item) {
            _this8.flowerNumber.push(item);
            _this8.detailForm.flowerNameId = item.id;
            _this8.detailForm.flower_name = item.flower_name;
            _this8.detailForm.category = item.leibie;
            _this8.detailForm.agent_num = item.number;
          });
        } else {
          _this8.detailForm.flowerNameId = '';
        }
        // this.flowerArray();
        // 公海、私海
        _this8.regression = data.sea_type == 0 ? true : false;
        var khbq = data.labelpeer.split(',').map(function (res) {
          return res;
        });
        _this8.detailForm.name = data.cname;
        _this8.detailForm.Telephone = data.mobile;
        _this8.detailForm.cphoneAdress = data.mobile_address;
        _this8.detailForm.Telephone2 = data.mobile1;
        _this8.detailForm.cphoneAdress2 = data.mobile1_address;
        _this8.detailForm.Telephone3 = data.mobile2;
        _this8.detailForm.cphoneAdress3 = data.mobile2_address;
        _this8.detailForm.mailbox = data.cemail;
        _this8.detailForm.QQ = data.qq;
        _this8.detailForm.remarks = data.notes;
        _this8.detailForm.WeChat = data.wxnum;
        _this8.detailForm.extensionName = data.communication_name;
        _this8.detailForm.extensionAddress = data.communication_website;
        _this8.detailForm.callNum = data.all_call_num;
        if (data.wxnum == '') {
          _this8.wxdisable = false;
        } else {
          _this8.wxdisable = true;
        }
        if (data.mobile1 == '') {
          _this8.mo1disable = false;
          _this8.show2 = false;
        } else {
          _this8.mo1disable = true;
          _this8.show2 = true;
        }
        if (data.mobile2 == '') {
          _this8.mo2disable = false;
          _this8.show3 = false;
        } else {
          _this8.mo2disable = true;
          _this8.show3 = true;
        }
        if (data.graduation_xueli == '0') {
          _this8.detailForm.educationText = '';
        } else {
          _this8.detailForm.educationText = String(data.graduation_xueli);
        }
        _this8.detailForm.areaOfInterest = data.ittngj;
        if (data.ittnxl == '0') {
          _this8.detailForm.projectTextId = '';
        } else {
          _this8.detailForm.projectTextId = String(data.ittnxl);
        }
        // 专业
        getGraduationMajor().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == _this8.detailForm.educationText) {
              _this8.educationText = item.value;
            }
          });
        });
        _getIttnxm().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == _this8.detailForm.projectTextId) {
              _this8.baokaixiangmu = item.value;
            }
          });
        });
        _this8.detailForm.GraduationMajor = data.graduation_major;
        _this8.detailForm.GraduatedFrom = data.graduate_school;
        _this8.detailForm.budget = data.budget;
        _this8.detailForm.sex = String(data.sex);
        if (data.entrance == '0') {
          _this8.detailForm.entranceTexts = '';
        } else {
          _this8.detailForm.entranceTexts = String(data.entrance); //预计入学
        }
        _this8.detailForm.post = data.post;
        _this8.detailForm.achievements = data.academic_achievements;
        if (data.age == '0') {
          _this8.detailForm.Age = '';
        } else {
          _this8.detailForm.Age = String(data.age);
        }
        _this8.detailForm.Landline = data.telephone;
        _this8.detailForm.occupation = data.occupation;
        _this8.detailForm.language = data.language_achievement;
        _this8.detailForm.industry = data.industry;
        _this8.detailForm.Passport = data.passport_number;
        _this8.detailForm.address = data.detailed_address;
        _this8.detailForm.labelpeer = khbq;
        _this8.detailForm.Student = _this8.getFathersByid(data.customer_level, _this8.StudentLevel);
        // lq新增
        _this8.detailForm.data_type = data.data_type != 0 ? data.data_type : '';
        _this8.is_data_type = data.is_data_type;
        // 第三方不必填
        // 不参与分配 不必填  数据类型是否有值为否 也不必填
        if (_this8.client_id != 0) {
          _this8.dataTypeOptions[0].disabled = false;
        }
        if (_this8.is_data_type == 1) {
          _this8.dataTypeOptions[0].disabled = true;
        } else {
          _this8.dataTypeOptions[0].disabled = false;
        }

        // 登录用户structure_id是815的，
        // 客户详情->数据类型，选项只展示社科高级
        if (_this8.$store.state.user.structure_id.indexOf(815) !== -1) {
          _this8.dataTypeOptions = _this8.dataTypeOptions.filter(function (item) {
            return item.id === 2 || item.id === 8888;
          });
        }
        _this8.distribution_land_status = data.distribution_land_status;
        _this8.flag = data.flag;
        _this8.platform_name = data.platform_name;
        _this8.page_id = data.page_id;
        _this8.wechatComponentData = Object.assign({
          message: '',
          remark: res.data.cname
        }, res.data);
        _this8.sms_switch = data.sms_switch;
        _this8.plaintext_mobile = '-1';
        _this8.plaintext_mobile_one = '-1';
        _this8.plaintext_mobile_two = '-1';
      });
    },
    // 编辑跳转页面
    editCustomer: function editCustomer() {
      // this.$router.push({
      //     path: '/addCustomer?pid=2&customer_id=' + this.customer_id + '&customer_data_id=' + this.customer_data_id
      // });

      var routeUrl = this.$router.resolve({
        path: '/addCustomer?pid=2&customer_id=' + this.customer_id + '&customer_data_id=' + this.customer_data_id
      });
      window.open(routeUrl.href, '_blank');
    },
    internationalWaters: function internationalWaters() {
      var _this9 = this;
      //回归公海
      this.$confirm('请确认是否将当前客户回归到公海？', '回归公海', {
        confirmButtonText: '继续回归',
        cancelButtonText: '取消'
      }).then(function () {
        MyCustomerRegression({
          customer_data_ids: _this9.customer_data_id
        }).then(function (res) {
          _this9.regression = false;
          _this9.$notify({
            title: '成功',
            message: '回归成功',
            type: 'success'
          });
          _this9.next_customer();
        });
      });
    },
    showEs: function showEs(e) {
      //编辑意向专业
      if (!e) {
        this.dblEditCustomer();
      }
    },
    GeneralEs: function GeneralEs(e) {
      //编辑意向简章
      if (!e) {
        this.dblEditCustomer();
      }
    },
    collegesEs: function collegesEs(e) {
      //编辑意向院校
      if (!e) {
        this.dblEditCustomer();
      }
    },
    transfer: function transfer() {
      //共享客户
      this.radioEs = true;
      this.mailListVisibleEs = true;
    },
    //转移跟进人
    followUpPerson: function followUpPerson() {
      this.radioUp = true;
      this.mailListVisibleUp = true;
    },
    //专一创建人
    createPerson: function createPerson() {
      this.radio = true;
      this.mailListVisible = true;
    },
    SharedSustomersUp: function SharedSustomersUp(resultNameArr, result) {
      var _this10 = this;
      //转移跟进人
      this.transferorid = String(result);
      MyCustomerTransfoll({
        customer_data_ids: String(this.customer_data_id),
        user_id: this.transferorid
      }).then(function (res) {
        _this10.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 2000
        });
        _this10.next_customer();
      });
    },
    maillistRadio: function maillistRadio(resultNameArr, result) {
      var _this11 = this;
      //转移创建人
      this.transferor = String(result);
      MyCustomerTransfer({
        customer_ids: String(this.customer_id),
        user_id: this.transferor
      }).then(function (res) {
        _this11.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 3000
        });
        _this11.next_customer();
      });
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      var _this12 = this;
      //选择共享给谁

      this.transferorid = String(result);
      MyShareSetshare({
        customer_ids: String(this.customer_id),
        user_id: this.transferorid
      }).then(function (res) {
        _this12.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 2000
        });
      });
    },
    flowerArray: function flowerArray(val) {
      var _this13 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        var hm = [];
        for (var i in _this13.flowerNumber) {
          if (_this13.flowerNumber[i] != '') {
            hm.push(_this13.flowerNumber[i]);
            for (var key in res.data) {
              hm.push(res.data[key]);
            }
          } else {
            for (var _key in res.data) {
              hm.push(res.data[_key]);
            }
          }
        }
        _this13.removeByValue(hm, 'id', 'null');
        _this13.flower_Array = _this13.dedupe(hm);
      });
    },
    flowerName: function flowerName() {
      var _this14 = this;
      //花名分页
      this.page++;
      agentFlowerNameList({
        page: String(this.page),
        pagesize: '15'
      }).then(function (res) {
        _this14.flower_Array.forEach(function (item) {
          _this14.flowerNumberStation.push(item);
        });
        for (var key in res.data.data) {
          _this14.flowerNumberStation.push(res.data.data[key]);
        }
        _this14.removeByValue(_this14.flowerNumberStation, 'id', 'null');
        var array = _this14.dedupe(_this14.flowerNumberStation);
        _this14.flower_Array = array;
      });
    },
    flowerArrayEs: function flowerArrayEs(val) {
      var _this15 = this;
      //改变花名
      agentFlowerNameList({
        page: String(1),
        pagesize: '15',
        key_word: val
      }).then(function (res) {
        res.data.forEach(function (item) {
          if (val == item.id) {
            _this15.detailForm.category = item.leibie;
            _this15.ruleForm.agent_num = item.number;
          }
        });
        _this15.flower_Array = res.data;
      });
    },
    flower: function flower(e) {
      //编辑花名
      if (!e) {
        this.dblEditCustomer();
      }
    },
    BrochuresList: function BrochuresList() {
      var _this16 = this;
      //意向简章默认
      _BrochuresList().then(function (res) {
        res.data.brochures_list.map(function (item) {
          item.id = String(item.id);
        });
        var zxjz = [];
        for (var i in _this16.GeneralRules) {
          zxjz.push(_this16.GeneralRules[i]);
          for (var key in res.data.brochures_list) {
            zxjz.push(res.data.brochures_list[key]);
          }
        }
        _this16.GenalRules = res.data.brochures_list;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this17 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        res.data.major_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxzy = [];
        for (var i in _this17.IntendedMajor) {
          yxzy.push(_this17.IntendedMajor[i]);
          for (var key in res.data.major_list) {
            yxzy.push(res.data.major_list[key]);
          }
        }
        _this17.Intendeds = res.data.major_list;
      });
    },
    getEntrance: function getEntrance() {
      var _this18 = this;
      _getEntrance().then(function (res) {
        //入学时间
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == _this18.detailForm.entranceText) {
            _this18.entranceTextEs = item.value;
            _this18.detailForm.entranceText = item.value;
          }
        });
        _this18.entrance = res.data;
      });
    },
    SchoolDer: function SchoolDer() {
      var _this19 = this;
      //意向院校默认
      School().then(function (res) {
        res.data.school_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxyx = [];
        for (var i in _this19.institu) {
          yxyx.push(_this19.institu[i]);
          for (var key in res.data.school_list) {
            yxyx.push(res.data.school_list[key]);
          }
        }
        _this19.chatmodes = res.data.school_list;
      });
    },
    prev_customer: function prev_customer() {
      //上一个客户
      if (this.shangsj) {
        if (this.pageTop == 1) {
          if (this.keyTop == 0) {
            this.$notify({
              title: '警告',
              message: '这是第一个客户',
              type: 'warning'
            });
          } else {
            this.keyTop--;
            this.MyCustomers();
          }
        } else {
          if (this.keyTop == 0) {
            this.pageTop--;
            this.keyTop = this.pagesizeTop - 1;
            this.MyCustomers();
          } else {
            this.keyTop--;
            this.MyCustomers();
          }
        }
      } else {
        this.$notify({
          title: '警告',
          message: '忙线中，暂不支持切换客户',
          type: 'warning'
        });
      }
    },
    next_customer: function next_customer() {
      var _this20 = this;
      //下一个客户
      if (this.shangsj) {
        if (this.list_typeEs == 1 || this.list_typeEs == 0) {
          var result = localStorage.getItem('resultES');
          var resultES = JSON.parse(result);
          resultES.page = Number(this.pageTop);
          resultES.pagesize = this.pagesizeTop;
          MyCustomerIndex(resultES).then(function (res) {
            var data = res.data.data;
            if (_this20.keyTop == _this20.pagesizeTop - 1) {
              for (var i in data) {
                if (data[i].customer_id == _this20.customer_id) {
                  _this20.pageTop++;
                  _this20.keyTop = 0;
                  break;
                } else {
                  _this20.pageTop;
                  _this20.keyTop;
                }
              }
              _this20.MyCustomers();
            } else {
              for (var _i in data) {
                if (data[_i].customer_id == _this20.customer_id) {
                  _this20.keyTop++;
                  break;
                } else {
                  _this20.keyTop;
                }
              }
              _this20.MyCustomers();
            }
          });
        } else {
          if (this.keyTop == this.pagesizeTop - 1) {
            this.pageTop++;
            this.keyTop = 0;
            this.MyCustomers();
          } else {
            this.keyTop++;
            this.MyCustomers();
          }
        }
      } else {
        this.$notify({
          title: '警告',
          message: '忙线中，暂不支持切换客户',
          type: 'warning'
        });
      }
    },
    MyCustomers: function MyCustomers() {
      var _this21 = this;
      //上下客户封装
      var result = localStorage.getItem('resultES');
      var resultES = JSON.parse(result);
      resultES.page = String(this.pageTop);
      resultES.pagesize = this.pagesizeTop;
      MyCustomerIndex(resultES).then(function (res) {
        for (var key in res.data.data) {
          if (key == _this21.keyTop) {
            // this.$router.push({
            //     path: '/myCustomerDetail',
            //     query: {
            //         Cid: res.data.data[key].customer_id,
            //         CData_id: res.data.data[key].customer_data_id,
            //         editRole: 1,
            //         key: this.keyTop,
            //         page: this.pageTop,
            //         pagesize: this.pagesizeTop,
            //         list_type: this.list_typeEs
            //     }
            // });
            _this21.detaiData = res.data.data[key];
            _this21.customer_id = res.data.data[key].customer_id;
            _this21.customer_data_id = res.data.data[key].customer_data_id;
            _this21.customer_id = res.data.data[key].customer_id;
            _this21.customer_data_id = res.data.data[key].customer_data_id;
            // this.FollwupIndex(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
            // this.GetDetails(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
            // this.Customerlog(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
            _this21.init();
          }
        }
        if (_this21.keyTop > res.data.data.length - 1) {
          _this21.$notify({
            title: '警告',
            message: '这是最一个客户',
            type: 'warning'
          });
          return _this21.keyTop = res.data.data.length - 1;
        }
      });
    },
    switchs: function switchs() {
      //操作日志开关
      if (this.isusertype == false) {
        this.isusertype = true;
        this.isusertypeName = '收起内容';
      } else {
        this.isusertype = false;
        this.isusertypeName = '查看全部';
      }
    },
    Customerlog: function Customerlog(log, dataId) {
      var _this22 = this;
      //操作日志
      MyCustomerCustomerlog({
        customer_id: log,
        customer_data_id: dataId
      }).then(function (res) {
        _this22.journal = res.data.list;
        _this22.enteredBy = res.data.create_info;
      });
    },
    changeShow: function changeShow(value, type) {
      this.changeShowValue(value, type);
    },
    GETPlaceofownership: function GETPlaceofownership(cphone, number) {
      var _this23 = this;
      //手机号验证/查询归属地
      if (!/^1[3456789]\d{9}$/.test(cphone)) {
        this.$notify({
          title: '警告',
          message: '手机号错误',
          type: 'warning'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        if (this.detailForm.Telephone === this.detailForm.Telephone2) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone2 = '';
        } else if (this.detailForm.Telephone2 === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else if (this.detailForm.Telephone === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else {
          GetPhoneAddress({
            phone: cphone
          }).then(function (res) {
            _this23.Show = true;
            if (number == 1) {
              _this23.plaintext_mobile = cphone;
              _this23.detailForm.cphoneAdress = res.data.province + res.data.city;
            } else if (number == 2) {
              _this23.detailForm.cphoneAdress2 = res.data.province + res.data.city;
              _this23.plaintext_mobile_one = cphone;
            } else if (number == 3) {
              _this23.plaintext_mobile_two = cphone;
              _this23.detailForm.cphoneAdress3 = res.data.province + res.data.city;
            }
            _this23.dblEditCustomer();
          });
        }
      }
    },
    sendEmail: function sendEmail() {
      //邮箱验证
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.detailForm.mailbox != '' && !regEmail.test(this.detailForm.mailbox)) {
        this.$message.error('邮箱格式不正确');
        this.detailForm.mailbox = '';
      }
    },
    QQverification: function QQverification(val) {
      //QQ号验证
      var regEmail = /^[1-9][0-9]{7,10}$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('QQ号格式不正确');
        this.detailForm.QQ = '';
      }
    },
    Landlines: function Landlines(val) {
      //座机号验证
      var regEmail = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('座机号格式不正确');
        this.detailForm.Landline = '';
      }
    },
    displayShow: function displayShow() {
      // 联系电话2和3的显示
      if (this.show2 == false) {
        this.show2 = true;
      } else {
        this.show3 = true;
      }
      if (this.detailForm.Telephone2 != '') {
        this.mo1disable = true;
      } else {
        this.mo1disable = false;
      }
      if (this.detailForm.Telephone3 != '') {
        this.mo2disable = true;
      } else {
        this.mo2disable = false;
      }
    },
    mobilePhones: function mobilePhones(number) {
      //隐藏清空电话
      if (number == 2) {
        this.show2 = false;
        this.detailForm.Telephone2 = '';
        this.plaintext_mobile_one = '';
        this.detailForm.cphoneAdress2 = '';
      } else {
        this.show3 = false;
        this.detailForm.Telephone3 = '';
        this.plaintext_mobile_two = '';
        this.detailForm.cphoneAdress3 = '';
      }
      if (this.detailForm.Telephone3 != '') {
        this.mo1disable = true;
      } else {
        this.mo1disable = false;
      }
      if (this.detailForm.Telephone3 != '') {
        this.mo2disable = true;
      } else {
        this.mo2disable = false;
      }
      this.dblEditCustomer();
    },
    education: function education() {
      var _this24 = this;
      //进入页面加载的接口
      getGraduationMajor().then(function (res) {
        //当前学历
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this24.educations = res.data;
      });
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this24.StudentLevel = res.data;
      });
    },
    getCustomerLevelEs: function getCustomerLevelEs() {
      var _this25 = this;
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this25.StudentLevel = res.data;
      });
    },
    labelpeer: function labelpeer() {
      var _this26 = this;
      getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this26.customer = res.data;
      });
    },
    getIttnxm: function getIttnxm() {
      var _this27 = this;
      _getIttnxm().then(function (res) {
        //报考项目
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this27.projects = res.data;
      });
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    educationSe: function educationSe(val) {
      var _this28 = this;
      //当前学历
      this.detailForm.educationText = val;
      getGraduationMajor().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this28.educationText = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    projectSe: function projectSe(val) {
      var _this29 = this;
      //报考项目
      this.detailForm.projectTextId = val;
      _getIttnxm().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this29.detailForm.projectText = item.value;
            _this29.dblEditCustomer();
          }
        });
      });
    },
    detailFormSex: function detailFormSex(val) {
      //男女
      this.detailForm.sex = val;
      this.dblEditCustomer();
    },
    detailFormEntrance: function detailFormEntrance(val) {
      var _this30 = this;
      //入学时间
      this.detailForm.entranceTexts = val;
      _getEntrance().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this30.detailForm.entranceTextEs = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    handleChange: function handleChange(val) {
      var _this31 = this;
      if (val == '') {
        this.detailForm.Student = '';
      } else {
        this.detailForm.Student = val[1] == undefined ? val[0] : val[1];
        if (this.$refs.cascaderAddr.getCheckedNodes()[0].data.is_hide == 1) {
          this.$confirm('学员级别修改为' + this.$refs.cascaderAddr.getCheckedNodes()[0].label + '时，则私海数据中不再展示，确认修改？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this31.dblEditCustomer();
          }).catch(function () {
            _this31.detailForm.Student = _this31.getFathersByid(_this31.customer_level, _this31.StudentLevel);
          });
        } else {
          this.dblEditCustomer();
        }
      }
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    FollwupIndex: function FollwupIndex(customer_id, customer_data_id) {
      var _this32 = this;
      this.activities = '';
      _FollwupIndex({
        id: Number(customer_id),
        customer_data_id: Number(customer_data_id),
        is_realname: 2
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this32.activities = res.data;
      });
    },
    NewFollowupRecordSure: function NewFollowupRecordSure() {
      var _this33 = this;
      //添加跟进
      if (this.form.typese == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.form.theme == '') {
        this.$message({
          type: 'warning',
          message: '请填写内容'
        });
      } else {
        FollwupAdd({
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          content: this.form.theme,
          type: Number(this.form.typese),
          next_time: this.form.next_follow.toString().substr(0, 10)
        }).then(function (res) {
          //入学时间
          _this33.FollwupIndex(_this33.customer_id, _this33.customer_data_id);
          _this33.$notify({
            title: '成功',
            message: '新增跟进成功',
            type: 'success'
          });
          _this33.form.theme = '';
          _this33.form.typese = '1';
          _this33.form.next_follow = '';
          _this33.dialogFormVisible = false;
          //this.getTimestamp(
        });
      }
    },
    dblEditCustomer: function dblEditCustomer() {
      var _this34 = this;
      //编辑信息
      var detailForm = this.detailForm;
      if (detailForm.name == '') {
        this.$message({
          type: 'warning',
          message: '客户名称输入有误'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        // 第三方不必填
        // 不参与分配 不必填
        if (this.is_data_type == 1 && this.client_id == 0 && (detailForm.data_type == '' || detailForm.data_type == 0)) {
          this.$message({
            message: '数据类型不能为空',
            type: 'warning'
          });
          return;
        }
        // 报考项目必填 第三方不必填
        if (this.detailForm.projectTextId == '' && this.client_id == 0) {
          this.$message({
            message: '报考项目不能为空',
            type: 'warning'
          });
          return;
        }
        var data = {
          customer_id: this.customer_id,
          customer_data_id: this.customer_data_id,
          cname: detailForm.name,
          //客户名称
          source_client_id: String(detailForm.flowerNameId == undefined ? '' : detailForm.flowerNameId),
          //花名
          qq: detailForm.QQ,
          //qq号
          wxnum: detailForm.WeChat,
          //微信号
          communication_name: detailForm.extensionName,
          //推广名称
          communication_website: detailForm.extensionAddress,
          cemail: detailForm.mailbox,
          //邮箱
          sex: detailForm.sex,
          //性别 0男 1女
          age: detailForm.Age,
          //年龄
          entrance: detailForm.entranceTexts,
          //预计入学
          post: detailForm.post,
          //岗位
          company: detailForm.occupation,
          //单位
          academic_achievements: detailForm.achievements,
          //学术成绩
          passport_number: detailForm.Passport,
          //护照号码
          language_achievement: detailForm.language,
          //语言成绩
          // communication_website: detailForm.communication, //发起沟通网址
          detailed_address: detailForm.address,
          //地址
          character: detailForm.hobby,
          //性格
          customer_level: detailForm.Student,
          //学员级别
          mobile_address: detailForm.cphoneAdress,
          //手机归属地1
          mobile1_address: detailForm.cphoneAdress2,
          //手机归属地2
          mobile2_address: detailForm.cphoneAdress3,
          //手机归属地3
          graduation_major: detailForm.GraduationMajor,
          //毕业专业
          graduate_school: detailForm.GraduatedFrom,
          //毕业院校
          ittngj: detailForm.areaOfInterest,
          //意向地区
          ittnzy: String(detailForm.IntendedMajor),
          //意向专业id 1,2,3
          ittnyx: String(detailForm.institu),
          //意向院校id 1,2,3
          ittnxm: detailForm.projectTextId,
          //报考项目id
          ittnjz: String(detailForm.GeneralRules),
          //意向简章id 1,2,3
          budget: detailForm.budget,
          //预算
          labelpeer: String(detailForm.labelpeer),
          //客户标签 1,2,3
          notes: detailForm.remarks,
          //备注
          telephone: detailForm.Landline,
          //座机
          occupation: detailForm.occupation,
          //职业
          industry: detailForm.industry,
          //行业
          create_notes: detailForm.appended,
          //创建附注
          graduation_xueli: detailForm.educationText,
          data_type: detailForm.data_type
        };
        // 手机号修改以后传 不修改不传
        if (this.plaintext_mobile != -1) {
          data.mobile = detailForm.Telephone; //手机号
        }
        if (this.plaintext_mobile_one != -1) {
          data.mobile1 = detailForm.Telephone2; //手机号1
        }
        if (this.plaintext_mobile_two != -1) {
          data.mobile2 = detailForm.Telephone3; //手机2
        }
        MyCustomerUpdate(data).then(function (res) {
          _this34.GetDetails(_this34.customer_id, _this34.customer_data_id);
          _this34.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success',
            duration: 2000
          });
          localStorage.setItem('operation', 'opera');
        });
      }
    },
    changeShowValue: function changeShowValue(value, type) {
      //悬浮状态
      if (Number(this.editRole)) {
        if (type) {
          var showArr = ['intentionally', 'agent_name', 'xuel', 'timeline', 'adress'];
          var showNum = 0;
          for (var key in showArr) {
            if (this.detailFormShow[showArr[key]] == false) {
              showNum = 1;
            }
          }
          for (var _key2 in this.detailFormShow) {
            if (_key2 == value) {
              this.detailFormShow[_key2] = false;
            } else if (showNum == 1) {
              this.detailFormShow[_key2] = true;
            }
          }
        } else {
          for (var _key3 in this.detailFormShow) {
            if (_key3 == value) {
              this.detailFormShow[_key3] = true;
            }
          }
        }
      }
    },
    //zml下次回访条数
    getUnfollowmsg: function getUnfollowmsg() {
      var _this35 = this;
      var next_time = this.form.next_follow.toString().substr(0, 10);
      getUnfollow({
        next_time: next_time
      }).then(function (res) {
        if (res.data == '') {
          _this35.Unfollowshow = false;
        } else {
          _this35.Unfollowshow = true;
          _this35.Unfollow = res.data;
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.orderPagesize = val;
      this.orderPage = 1;
      this.handleClick({
        label: '成交订单'
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.orderPage = val;
      this.handleClick({
        label: '成交订单'
      });
    },
    handleClick: function handleClick(tab, event) {
      var _this36 = this;
      if (tab.label == '成交订单') {
        var data = {
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          pagesize: this.orderPagesize,
          page: this.orderPage
        };
        orderlist(data).then(function (res) {
          res.data.data.map(function (item) {
            item.pay_purpose = item.pay_purpose.split(',');
          });
          _this36.tableData = res.data.data;
          _this36.total = res.data.total;
        });
      }
    },
    orderDetails: function orderDetails(row, val) {
      if (row.order_type == 1) {
        var routeUrl = this.$router.resolve({
          path: '/orderDetails?Cid=' + row.cid + '&id=' + row.id + '&types=1'
        });
        window.open(routeUrl.href, '_blank');
      } else {
        var _routeUrl = this.$router.resolve({
          path: '/orderDetailsts?Cid=' + row.cid + '&id=' + row.id + '&types=1'
        });
        window.open(_routeUrl.href, '_blank');
      }
    },
    // 选择数据类型
    changeDataTypeFun: function changeDataTypeFun(val) {
      var _this37 = this;
      var type = [];
      // 根据分配条件配置，数据类型是否必须有值
      // 为是时：
      // 1、数据类型修改成功后，数据进入对应待分配的数据池
      // 2、数据类型选择后弹框二次确认
      // 3、数据类型修改后，自动分配完成前不支持再修改，自动分配完成后支持修改
      //  如果不是参与分配人员，或者已经分配成功的数据，修改数据类型不弹框

      // 为否时：
      // 1、数据录入系统成功时即进入对应待分配的数据池
      // 2、数据类型修改不做限制
      if (val != '') {
        type = this.dataTypeOptions.filter(function (item) {
          return item.value == val;
        });
        var arr = [0, 1, 2];
        if (this.is_data_type == 1 && this.distribution_land_status == 0 && this.page_id == '' && (this.flag == '4' && this.platform_name == '易聊' || arr.indexOf(this.flag) != -1)) {
          this.$confirm("\u81EA\u52A8\u5206\u914D\u524D\u4E0D\u53EF\u518D\u4FEE\u6539\u6570\u636E\u7C7B\u578B\uFF0C\u786E\u8BA4\u4FEE\u6539\u4E3A".concat(type[0].label, "\uFF1F"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this37.dblEditCustomer();
          }).catch(function () {
            _this37.detailForm.data_type = '';
          });
        } else {
          this.dblEditCustomer();
        }
      } else {
        if (this.is_data_type == 1 && this.client_id == 0 && (this.detailForm.data_type == '' || this.detailForm.data_type == 0)) {
          this.$message({
            message: '数据类型不能为空',
            type: 'warning'
          });
        } else {
          this.dblEditCustomer();
        }
      }
    },
    onUpdateQueryFun: function onUpdateQueryFun() {
      this.GetDetails(this.customer_id, this.customer_data_id);
    }
  },
  destroyed: function destroyed() {
    var _this38 = this;
    var _this = this;
    document.removeEventListener('visibilitychange', _this.handleVisiable);
    window.removeEventListener('beforeunload', function (e) {
      return _this38.beforeunloadHandler(e);
    });
  }
};